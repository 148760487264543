export const Navbar = () => {
    return (<>
            <div className={"header-navbar"}>
                <div>
                    <ul>
                        <li><a href="/">Accueil</a></li>
                        <li>
                            <a href="">
                                <img style={{
                                    width: "100px",
                                    height: "100px"
                                }} src="./Logo-JNM_20230924%20(1).png" alt=""/>
                            </a>
                        </li>
                        <li><a href="/partenaires">Partenaires</a></li>
                    </ul>
                    <ul className="responsive">
                        <li style={{visibility: 'hidden'}}>
                            <button onClick={() => {
                                // set display: block to responsive-menu
                                if (document.querySelector(".responsive-menu").style.display === "block") {
                                    document.querySelector(".responsive-menu").style.display = "none";
                                } else {
                                    document.querySelector(".responsive-menu").style.display = "block";
                                }
                            }}><i className="fa-solid fa-bars"></i></button>
                        </li>
                        <li>
                            <a href="">
                                <img style={{
                                    width: "100px",
                                    height: "100px"
                                }} src="./Logo-JNM_20230924%20(1).png" alt=""/>
                            </a>
                        </li>
                        <li>
                            <button onClick={() => {
                                // set display: block to responsive-menu
                                if (document.querySelector(".responsive-menu").style.display === "block")
                            {
                                document.querySelector(".responsive-menu").style.display = "none";
                            } else {
                                document.querySelector(".responsive-menu").style.display = "block";
                            }
                        }}><i className="fa-solid fa-bars"></i></button></li>
                    </ul>
                </div>
            </div>

            <div className={"responsive-menu"}>
                <ul>
                    <li><a href="/">Accueil</a></li>
                    <li><a href="/partenaires">Partenaires</a></li>
                </ul>
            </div>
        </>
    )
}
