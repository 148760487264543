import {Route, Routes} from "react-router-dom";
import {Home} from "./Home";
import {Partenaires} from "./Partenaires";
import {CGU} from "./CGU";
import Timetable from "./Timetable";

export const Root = () => {
    return (
        <Routes>
            <Route path="/" element={<Home/>} />
            <Route path="/partenaires" element={<Partenaires/>} />
            <Route path="/cgu" element={<CGU/>} />
            <Route path="/edt" element={<Timetable/>} />
        </Routes>
    );
}
