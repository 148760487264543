import {Navbar} from "./Navbar";
import {AttentionSeeker, Bounce, Fade, Hinge, JackInTheBox, Roll, Slide} from "react-awesome-reveal";

export const CGUHeader = () => {
    return (
        <div className="classic-header">
            <Navbar/>
            <div className="title">
                <Fade triggerOnce={true}>
                    Conditions Générales d'Utilisation
                </Fade>
            </div>
        </div>
    )
}
