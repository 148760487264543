import {Navbar} from "./Navbar";
import {AttentionSeeker, Bounce, Fade, Hinge, JackInTheBox, Roll, Slide} from "react-awesome-reveal";

export const ClassicHeader = () => {
    return (
        <div className="classic-header">
            <Navbar/>
            <div className="title">
                <Fade triggerOnce={true}>
                    Nos partenaires
                </Fade>
            </div>
        </div>
    )
}
