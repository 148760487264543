import React, {useState} from 'react';
import '../styles/edt.css';
import {HomeHeader} from "../components/HomeHeader";
import {EDTHeader} from "../components/EDTHeader";


const Timetable = () => {

    return (
        <>
            <div className="app-container">
                <EDTHeader/>
                <div style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginTop: '20px',
                    marginBottom: '20px',

                }}>
                    <a style={{
                        padding: '10px',
                        marginRight: '10px',
                        backgroundColor: '#007bff',
                        color: 'white',
                        borderRadius: '5px',
                        textDecoration: 'none'

                    }} href="./edt-exported.pdf">Voir en format PDF</a>
                </div>
                <img src={'./Mercredi.png'}
                     alt="Emploi du temps"
                />
            </div>
        </>
    );
};

export default Timetable;
