import {HomeHeader} from "../components/HomeHeader";
import {SliderPart} from "../components/SliderPart";
import {Bounce, Fade, JackInTheBox, Reveal, Rotate, Slide, Zoom} from "react-awesome-reveal";
import {useNavigate, useNavigation} from "react-router-dom";
import {MapContainer, Marker, Popup, TileLayer} from "react-leaflet";
import {useEffect, useState} from "react";

export const Home = () => {

    const navigation = useNavigate();

    function getCurrentYear() {
        return new Date().getFullYear();
    }

    const position = [51.505, -0.09]

    const [jour, setJour] = useState(0);
    const [heure, setHeure] = useState(0);
    const [minute, setMinute] = useState(0);
    const jourAvantJnm = () => {
        const dateJNM = new Date('2024-05-22T00:00:00');
        const maintenant = new Date();
        const difference = dateJNM.getTime() - maintenant.getTime();
        const joursRestants = Math.floor(difference / (1000 * 60 * 60 * 24));
        const secondesRestantes = Math.floor((difference / 1000) % 60);
        const heuresRestantes = Math.floor((difference / (1000 * 60 * 60)) % 24);
        const minutesRestantes = Math.floor((difference / (1000 * 60)) % 60);
        setJour(joursRestants);
        setHeure(heuresRestantes);
        setMinute(minutesRestantes);
    }

    useEffect(() => {
        const interval = setInterval(() => {
            jourAvantJnm();
        }, 1000);
    }, []);



    return (
        <div className="app-container">
            <HomeHeader/>
            <Fade triggerOnce={true}>
                <SliderPart/>
            </Fade>

            <div className="main-content" style={{}}>
                <div style={{
                    marginTop: '200px',
                }}>
                    <center>
                        <h1 className={"miage-explain-title"} style={{
                            color: "rgba(51, 47, 47, 0.91)"
                        }}>La MIAGE, c'est quoi ?</h1>
                    </center>

                    <div className={"miage-paragraph"} style={{
                        paddingLeft: 50,
                        paddingRight: 50,
                        paddingBottom: 50,
                        display: "flex",

                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                    }}>
                        <div className={"miage-explain"} style={{
                            fontFamily: 'Roboto',
                            fontSize: '1.2rem',
                            lineHeight: '1.5',
                            color: 'rgba(51, 47, 47, 0.91)',
                        }}>
                            Le Master MIAGE, un diplôme de niveau Bac+5 aligné sur le système LMD européen, allie
                            compétences en informatique et en gestion pour former des professionnels polyvalents. Les
                            diplômés sont reconnus pour leur capacité à s'adapter rapidement aux évolutions
                            technologiques et organisationnelles, ce qui les rend très recherchés pour des postes de
                            direction et d'encadrement dans divers secteurs de l'informatique et de l'entreprise.
                        </div>
                        <button className={"cta-explain"} style={{
                            backgroundColor: 'rgba(51, 47, 47, 0.91)',
                            border: "none",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "10px 30px",
                            borderRadius: 5,
                            cursor: "pointer",
                            marginTop: 10,
                            color: 'white',
                        }}
                                onClick={() => {
                                    window.open("https://www.miage.fr/", "_blank");
                                }}
                        >
                            En savoir plus sur la MIAGE
                            <i style={{marginLeft: 5}} className="fa-solid fa-angle-right"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div className="main-content">
                <Zoom triggerOnce={true} cascade={true}>
                    <div className="section-content" style={{}}>
                        <div className="left">
                            <h1>Conférences</h1>
                        </div>
                        <div className="right image">
                            <img src="./conf.jpg" alt=""
                                 style={{
                                     width: "100%",
                                     height: "100%"
                                 }}
                            />
                        </div>
                    </div>
                </Zoom>
                <Zoom triggerOnce={true} cascade={true}>
                    <div className="section-content" style={{}}>
                        <div className="right image">
                            <img src="./gala.jpg" alt=""
                                 style={{
                                     width: "100%",
                                     height: "100%"
                                 }}
                            />
                        </div>
                        <div className="left">
                            <h1>Gala</h1>
                        </div>
                    </div>
                </Zoom>

                <Zoom triggerOnce={true} cascade={true}>
                    <div className="section-content" style={{}}>
                        <div className="left">
                            <h1>Cohésion</h1>
                        </div>
                        <div className="right image">
                            <img src="./cohesion.jpg" alt=""
                                 style={{
                                     width: "100%",
                                     height: "100%"
                                 }}
                            />
                        </div>
                    </div>
                </Zoom>
            </div>
            <div className={"mobile-pict"}>
                <div className={"mobile-pict-card"}>
                    <div>
                        <h1>Conférences</h1>
                    </div>
                    <img src="./conf.jpg" alt=""
                         style={{
                             width: "100%",
                             height: "100%"
                         }}
                    />
                </div>
                <div className={"mobile-pict-card"}>
                    <div>
                        <h1>Gala</h1>
                    </div>
                    <img src="./gala.jpg" alt=""
                         style={{
                             width: "100%",
                             height: "100%"
                         }}
                    />
                </div>
                <div className={"mobile-pict-card"}>
                    <div>
                        <h1>Cohésion</h1>
                    </div>
                    <img src="./cohesion.jpg" alt=""
                         style={{
                             width: "100%",
                             height: "100%"
                         }}
                    />
                </div>
            </div>

            <div className={"main-content"} style={{
                backgroundColor: "rgb(128, 104, 70)",
            }}>
                <center>
                    <h1>Et si nous nous retrouvions ? <i className="fa-solid fa-map-location-dot"></i></h1>
                </center>
                <div className="contact">
                    <div className={"contact-card"}>
                    <div style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                        }}>
                            <div style={{

                            }}>
                                <div>
                                </div>

                                <div>
                                    <p style={{
                                        fontSize: "1.3rem",
                                        textAlign: "center",
                                    }}>Et si nous nous retrouvions bientôt ?</p>
                                    <div className="countdown">
                                        <div className="element">
                                            <p>{jour}</p>
                                            <p>Jours</p>
                                        </div>
                                        <div className="element">
                                            <p>{heure}</p>
                                            <p>Heures</p>
                                        </div>
                                        <div className="element">
                                            <p>{minute}</p>
                                            <p>Minutes</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"contact-card"}>
                        <button onClick={() => {
                            window.open("https://www.helloasso.com/associations/journee-nationales-miage-2024/evenements/journees-nationales-miage-2024", "_blank");
                        }}>
                            Inscription aux JNM 2024
                        </button>
                        <button onClick={() => {
                            window.open("https://docs.google.com/forms/d/e/1FAIpQLSfTYc58p02-ab0MHNXtCnEb1AY6KWlXX-HEMDO0bqlBCUi56Q/viewform?usp=pp_url", "_blank");
                        }}>
                                    Déposer mon CV
                        </button>
                    </div>
                </div>
            </div>

            <div className="footer">
                <div>
                    <img
                        style={{
                            width: "80px",
                            height: "80px"
                        }}
                        alt="JNM"
                        src="Logo-JNM_20230924%20(1).png"/>
                </div>
                <p style={{
                    color: 'white',
                    fontFamily: 'Quicksand'
                }}>
                    © {getCurrentYear()} Journées de la MIAGE - Tous droits réservés
                </p>

                <a href="/cgu"
                   target={"_blank"}
                   className={"cgu-link"}>
                    Mentions légales
                </a>
                <Rotate>
                    <div style={{
                        marginTop: 10,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-end",
                    }}>
                        <a href="https://www.facebook.com/people/JNM-2024/61552222584998/">
                            <i className="fab fa-facebook-f"/>
                        </a>
                        <a href="https://www.instagram.com/jnm2024_orleans/">
                            <i className="fab fa-instagram"/>
                        </a>
                        <a href="https://www.linkedin.com/company/jnm-2024/">
                            <i className="fab fa-linkedin"/>
                        </a>
                    </div>
                </Rotate>
            </div>
        </div>
    )
}
