import './App.css';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import {Root} from "./pages/Root";

function App() {

    const router = createBrowserRouter([
        { path: "*", Component: Root },
    ]);
    return (
        <RouterProvider router={router}/>
    );
}

export default App;
