import {HomeHeader} from "../components/HomeHeader";
import {Navbar} from "../components/Navbar";
import {ClassicHeader} from "../components/ClassicHeader";
import {Fade, Rotate, Slide, Zoom} from "react-awesome-reveal";

export const Partenaires = () => {

    const getCurrentYear = () => {
        return new Date().getFullYear();
    }

    const partRoi = [
        {
            logo: "./part/logo EI+CM.jpg",
            name: "Euro-Information",
            text: "Euro-Information, filiale technologique de Crédit Mutuel Alliance Fédérale, conçoit, réalise, maintient et exploite un système d’information commun utilisé par le Groupe.\n" +
                "\n" +
                "Les activités de développement et de production informatique au niveau national et international sont assurées par environ 4200 salariés répartis sur plusieurs sites géographiques au niveau national : Strasbourg, Nancy, Dijon, Orléans, Lyon, Lille, Cergy, Val de Fontenay, Paris et Nantes. \n" +
                "\n" +
                "www.e-i.com\n",
            siteweb: "https://www.e-i.com/fr/index.html",
            linkedin: "https://www.linkedin.com/company/euro-informations-developpements-eid/",
        },
        {
            name: "Sopra Steria",
            logo: "./part/SOPRASTERIA_logo_CMJN_exe.jpg",
            text: "Sopra Steria, acteur majeur de la Tech en Europe avec 56 000 collaborateurs dans près de 30 pays, est reconnu pour ses activités de conseil, de services numériques et d’édition de logiciels. Il aide ses clients à mener leur transformation digitale et à obtenir des bénéfices concrets et durables. Sopra Steria place l’humain au centre de son action et s’engage auprès de ses clients à tirer le meilleur parti du digital pour construire un avenir positif.\n" +
                "Retrouvez-nous sur www.soprasteria.com/fr",
            siteweb: "https://www.soprasteria.com/fr",
            linkedin: "https://www.linkedin.com/company/soprasteria/",

        },
        {
            name: "Acensi",
            logo: "./part/ACENSI - Bleu - Transparent.png",
            text: "Le Groupe ACENSI, positionné entre le cabinet de conseil généraliste et l’ESN spécialisée,\n" +
                "intervient depuis 2003 auprès de plus de 90 clients dans la gestion de leurs projets IT. Notre expertise\n" +
                "s’exerce dans divers secteurs d’activités en France mais aussi à l’international.\n" +
                "Présent en Région Centre depuis 2015 ce sont plus de 100 consultants qui accompagnent nos\n" +
                "clients en Assurance et Protection sociale sur leurs projets d’évolution de SI. Nos équipes\n" +
                "interviennent côté fonctionnel et technique.\n" +
                "Venez découvrir ACENSI sur notre stand ou contactez-nous à orleans@acensi.fr !",
            siteweb: "https://www.acensi.fr/page/accueil/fr_fr",
            linkedin: "https://www.linkedin.com/company/acensi/?originalSubdomain=fr",
        },
    ]

    const partDuc = [
        {
            name: "Atos",
            logo: "./part/atos.png",
            text: "Chez Atos, nous développons, des solutions numériques variées et stratégiques en matière d'intelligence artificielle, de cloud, de gestion des infrastructures, de décarbonation et d'expérience collaborateur.\n" +
                "Notre ambition : Créer des fondations numériques fiables et agiles permettant d'avoir un impact positif pour nos clients et la société. C'est ce que nous appelons le « Power of We ».\n" +
                "87% des alternants et stagiaires recommandent Atos (Enquête Choose my Company).\n" +
                "Nos opportunités : Jobs at Atos",
            siteweb: "https://atos.net/fr/",
            linkedin: "https://www.linkedin.com/company/atos/?originalSubdomain=fr",
        },
        {
            name: "CGI",
            logo: "./part/cgi.png",
            text: "Perfectionnez vos compétences. Partagez vos perspectives. Réalisez votre potentiel.\n" +
                "\n" +
                "\n" +
                "Vous souhaitez renforcer vos compétences et en acquérir de nouvelles, exprimer votre point de vue pour influer sur l’avenir et révéler toutes vos capacités ?\n" +
                "\n" +
                "\n" +
                "Rencontrez CGI, leader mondial du conseil & des services numériques, pour échanger et découvrir les dernières innovations Tech.\n" +
                "\n" +
                "\n" +
                "Au programme : IA, numérique responsable et serious game !\n" +
                "\n" +
                "\n" +
                "Transformez vos idées en actions 👉 https://www.cgi.com/france/fr-fr/recrute\n",
            linkedin: "https://www.linkedin.com/company/cgi/?originalSubdomain=fr",
            siteweb: "https://www.cgi.com/france/fr-fr",
        },
    ]

    const partVicomte = [
        {
            name: "Infotel",
            logo: "./part/LOGO INFOTEL CMJN.jpg",
            text: "Infotel accompagne les directions Informatiques et Métiers des grandes entreprises depuis 45 ans. Nous comptons aujourd’hui 16 agences en France et plus de 3 000 collaborateurs. \n" +
                "Notre agence d'Orléans est installée sur la région depuis 8 ans. Elle compte à ce jour une soixantaine de collaborateurs. Nous adressons des prestations allant de l'AMOA à la conception et au développement de logiciels jusqu'aux tests des produits développés.\n" +
                "Pour en savoir plus, n’hésitez pas à nous écrire sur recrutement-orleans@infotel.com.  \n" +
                "Retrouvez l’agence d’Orléans et toutes les infos Infotel sur infotel.com et sur https://www.linkedin.com/company/infotel/\n",
            linkedin: "https://www.linkedin.com/company/infotel/?originalSubdomain=fr",
            siteweb: "https://infotel.com/"
        },
        {
            name: "Apside",
            logo: "./part/apsidee.png",
            text: " Dans un environnement marqué par une accélération des évolutions technologiques, de transformations des usages et de disruptions majeures, Apside est un partenaire de confiance qui accompagne ses clients à créer de la valeur et à adresser leurs enjeux stratégiques en leur mettant à disposition des expertises technologiques (Data / IA, Cloud, Cyber) et une expérience sectorielle (Industrie, Banque, Assurance, Service, Secteur Public). Pour un accompagnement global, le groupe propose des offres transverses autour du Handicap (Apsid’EA), du Digital Learning, et du Conseil.  \n",
            siteweb: "https://www.apside.com/fr/",
            linkedin: "https://www.linkedin.com/company/apside/?originalSubdomain=fr",
        },
    ]
    const partChevalier = [
        {
            name: "Cat-Amania",
            logo: "./part/Logo%20Cat-Amania.png",
            text: "Cat-Amania est une société de conseil et de services numériques créée pour réaliser la transformation digitale de ses clients. Elle se fonde sur ses expertises métier, technique et méthodologique pour accompagner ses clients dans leurs grands projets de transformation.\n" +
                "Cat-Amania définit et participe à l’évolution du système d’information de ses clients dans les domaines de la banque, de l’assurance, de la grande distribution, de l’industrie et du secteur public. Avec plus de 20 ans d’expérience, Cat-Amania est en mesure de répondre aux besoins de ses clients avec des offres adaptables sur les thématiques d’Architecture, de Conseil, d’Etude, de Développement, de Test, de Paramétrage et de Pilotage. \n",
            siteweb: "https://www.cat-amania.com/",
            linkedin: "https://www.linkedin.com/company/cat-amania/?originalSubdomain=fr",
        },
        {
            name: "Randstad Digital",
            logo: "./part/randstad.png",
            text:"Nous fournissons des solutions globales dans 4 catégories clés: expérience client, cloud & infrastructure, data & analytics et l'ingénierie produit & digitale.\n" +
                "\n" +
                "\n" +
                "Nous savons qu'il faut des personnes talentueuses pour favoriser le changement digital, c'est pourquoi nous donnons accès à nos clients à des talents qualifiés.\n" +
                "\n" +
                "\n" +
                "Le choix de nos écoles partenaires joue un rôle important. Qu'il s'agisse d’apporter du support technique aux équipes existantes, de tirer parti de nos pôles de compétences internationaux, ou de bénéficier de solutions sur-mesure dans des domaines ciblés.\n" +
                "\n",
            siteweb: "https://www.randstaddigital.fr/fr/",
            linkedin: "https://www.linkedin.com/company/randstad-digital-france/?originalSubdomain=fr",
        },
        {
            name: "Extia",
            logo: "./part/extia.png",
            text: "\"D’abord qui, ensuite quoi\", c’est la marque de fabrique d’Extia. Partir du \"Qui\", de la personne, pour ensuite co-construire le \"Quoi\", un projet porteur de sens et de valeur. Depuis 2007, nous intervenons sur les métiers de l'IT, développement, infrastructure, devops ou encore chefferie de projet. Nous sommes passés de 3 à 2 500 Extiens, tout en parvenant à conserver un niveau de satisfaction élevé chez nos collaborateurs. Une satisfaction qui se traduit notamment par une présence ininterrompue au palmarès Great Place to Work et à la première place France en 2024.",
            siteweb: "https://www.extia-group.com/",
            linkedin: "https://www.linkedin.com/company/extia/?originalSubdomain=fr",
        },
        {
            name: "l'Agirc-Arrco",
            logo: "./part/agirc.png",
            text: "Grâce à ses 1000 talents, la DSI Agirc-Arrco conçoit, développe, teste et exploite les applicatifs informatiques de la retraite complémentaire Agirc-Arrco en mobilisant des équipes infrastructures et réseaux, des chargés des méthodes et des normes, de l’architecture technique et fonctionnelle, de la sécurité. Son système d’information s’appuie sur un data center propriétaire.\n" +
                "Les équipes pilotent une feuille de route dense, un plan de charges pluriannuel et 180 recrutements sont prévus en trois ans.",
            siteweb: "https://www.agirc-arrco.fr/",
            linkedin: "https://www.linkedin.com/company/agirc-arrco/?originalSubdomain=fr",
        },
        {
            name: "MC2i",
            logo: "./part/mc2i.png",
            text: "mc2i est un cabinet de conseil en transformation numérique qui accompagne les grandes entreprises et organismes publics dans leur transition numérique.\n" +
                "\n" +
                "Nos expertises sont le fruit d'intelligences individuelles et collectives et nos offres sont avant tout pensées par l’humain et pour l’humain.\n" +
                "\n" +
                "Notre raison d’être : \"Éclairer et transformer les organisations pour une performance durable, par un numérique responsable, inclusif et de confiance\". Avec une approche \"Human first, Digital explorers.\", nous nous engageons à faire de la transformation numérique une révolution positive.",
            siteweb: "https://www.mc2i.fr/",
            linkedin: "https://www.linkedin.com/company/mc2i/?originalSubdomain=fr",
        },

        {
            name: 'Crédit Agricole Titre',
            logo: './part/CA_titre.jpg',
            text:'Crédit Agricole Titres est une filiale du Groupe CA qui accompagne toutes les grandes mutations des marchés financiers et des titres pour ses clients, les 39 Caisses régionales du CA, le LCL et d’autres banques privées.\n' +
                'Son expertise concerne plusieurs métiers :\n' +
                '- Conservation Titres\n' +
                '- Tenue de compte client\n' +
                '- Epargne salariale\n' +
                '- Epargne bancaire\n' +
                '- Mobilité clients\n' +
                'Chiffres clés : 3 sites en France, 915 collaborateurs\n' +
                'Notre ambition ? Accompagner le développement de nos clients et offrir des prestations à un haut niveau de qualité et à des prix performants. Comment ? Grâce à nos Richesses Humaines, composées de professionnels dont l’expertise est dédiée à la satisfaction de nos clients.\n' +
                '\n',
            siteweb: 'https://www.ca-titres.fr/',
            linkedin: 'https://www.linkedin.com/company/credit-agricole-titres/',
        },
        {
            name: 'Thélem',
            logo: './part/thelem.jpg',
            text: 'Thélem Assurances, basé à Chécy, conçoit, commercialise et gère une gamme complète de produits d\'assurance destinés à protéger la famille (santé, accidents de la vie, épargne, prévoyance), les biens (habitation, automobile, outils de travail) et la responsabilité (personnelle et professionnelle). Si vous partagez nos valeurs (Innovation, Proximité et Attention, Responsabilité 360, Excellence), pourquoi ne pas rejoindre notre aventure collective au sein d\'un environnement de travail stimulant, centré sur des projets porteurs de sens !\n' +
                'Nous recrutons divers profils, venez les voir sur notre site !\n' +
                '\n',
            siteweb: 'https://www.thelem-assurances.fr/',
            linkedin: 'https://www.linkedin.com/company/thelem-assurances/?originalSubdomain=fr',
        }
    ]

    return (
        <div className="app-container">
            <ClassicHeader/>
            <div className="part-cards" style={{}}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    <h1 className={"part-title"}>Roi</h1>
                </div>
                {partRoi.map((part, index) => (
                    <Slide direction={"right"} triggerOnce={true}>
                        <div className="part-card">
                            <div className="left-part">
                                <p>
                                    {part.text}

                                    <div style={{
                                        marginTop: '10px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        flexDirection: 'column'
                                    }}>
                                        <p>
                                            Pour en savoir plus sur {part.name}
                                        </p>
                                        <div style={{flexDirection: 'row', }}>
                                            <a href={part.siteweb}>
                                                <i style={{ fontSize: '25px', padding: 5, borderRadius: 10, color: '#1e1c1c',background: 'white'}}  className="fa-solid fa-link"></i>
                                            </a>
                                            <a href={part.linkedin}  style={{ margin: 5,}}>
                                                <i style={{ fontSize: '25px',  padding: 5, borderRadius: 10, color: '#1e1c1c', background: 'white'}} className="fa-brands fa-linkedin"></i>
                                            </a>
                                        </div>
                                    </div>
                                </p>
                            </div>
                            <div className="right-part">
                                <img
                                    className={"post-part-img"}
                                    src={part.logo} alt=""/>
                            </div>

                        </div>
                    </Slide>
                ))}
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 80,
                }}>
                    <h1 className={"part-title"}>Duc</h1>
                </div>
                {partDuc.map((part, index) => (
                    <Slide direction={"left"} triggerOnce={true}>
                        <div className="part-card">
                            <div className="right-part">
                                <img
                                    className={"post-part-img"}
                                    src={part.logo} alt=""/>
                            </div>
                            <div className="left-part">
                                <p>
                                    {part.text}

                                    <div style={{
                                        marginTop: '10px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        flexDirection: 'column'
                                    }}>
                                        <p>
                                            Pour en savoir plus sur {part.name}
                                        </p>
                                        <div style={{flexDirection: 'row',}}>
                                            <a href={part.siteweb}>
                                                <i style={{
                                                    fontSize: '25px',
                                                    padding: 5,
                                                    borderRadius: 10,
                                                    color: '#1e1c1c',
                                                    background: 'white'
                                                }} className="fa-solid fa-link"></i>
                                            </a>
                                            <a href={part.linkedin} style={{margin: 5,}}>
                                                <i style={{
                                                    fontSize: '25px',
                                                    padding: 5,
                                                    borderRadius: 10,
                                                    color: '#1e1c1c',
                                                    background: 'white'
                                                }} className="fa-brands fa-linkedin"></i>
                                            </a>
                                        </div>
                                    </div>
                                </p>
                            </div>
                        </div>
                    </Slide>
                ))}
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 80,
                }}>
                    <h1 className={"part-title"}>Vicomte</h1>
                </div>
                {partVicomte.map((part, index) => (
                    <Slide direction={"right"} triggerOnce={true}>
                        <div className="part-card">
                            <div className="left-part">
                                <p>
                                    {part.text}

                                    <div style={{
                                        marginTop: '10px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        flexDirection: 'column'
                                    }}>
                                        <p>
                                            Pour en savoir plus sur {part.name}
                                        </p>
                                        <div style={{flexDirection: 'row',}}>
                                            <a href={part.siteweb}>
                                                <i style={{
                                                    fontSize: '25px',
                                                    padding: 5,
                                                    borderRadius: 10,
                                                    color: '#1e1c1c',
                                                    background: 'white'
                                                }} className="fa-solid fa-link"></i>
                                            </a>
                                            <a href={part.linkedin} style={{margin: 5,}}>
                                                <i style={{
                                                    fontSize: '25px',
                                                    padding: 5,
                                                    borderRadius: 10,
                                                    color: '#1e1c1c',
                                                    background: 'white'
                                                }} className="fa-brands fa-linkedin"></i>
                                            </a>
                                        </div>
                                    </div>
                                </p>
                            </div>
                            <div className="right-part">
                                <img
                                    className={"post-part-img"}
                                    src={part.logo} alt=""/>
                            </div>
                        </div>
                    </Slide>
                ))}
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 80,
                }}>
                    <h1 className={"part-title"}>Chevalier</h1>
                </div>
                {partChevalier.map((part, index) => (
                    <Slide direction={"left"} triggerOnce={true}>
                        <div className="part-card">
                            <div className="right-part">
                                <img
                                    className={"post-part-img"}
                                    src={part.logo} alt=""/>
                            </div>
                            <div className="left-part">
                                <p>
                                    {part.text}

                                    <div style={{
                                        marginTop: '10px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        flexDirection: 'column'
                                    }}>
                                        <p>
                                            Pour en savoir plus sur {part.name}
                                        </p>
                                        <div style={{flexDirection: 'row',}}>
                                            <a href={part.siteweb}>
                                                <i style={{
                                                    fontSize: '25px',
                                                    padding: 5,
                                                    borderRadius: 10,
                                                    color: '#1e1c1c',
                                                    background: 'white'
                                                }} className="fa-solid fa-link"></i>
                                            </a>
                                            <a href={part.linkedin} style={{margin: 5,}}>
                                                <i style={{
                                                    fontSize: '25px',
                                                    padding: 5,
                                                    borderRadius: 10,
                                                    color: '#1e1c1c',
                                                    background: 'white'
                                                }} className="fa-brands fa-linkedin"></i>
                                            </a>
                                        </div>
                                    </div>
                                </p>
                            </div>
                        </div>
                    </Slide>
                ))}
            </div>
            <div className="footer">
                <div>
                    <img
                        style={{
                            width: "80px",
                            height: "80px"
                        }}
                        alt="JNM"
                        src="Logo-JNM_20230924%20(1).png"/>
                </div>
                <p style={{
                    color: 'white',
                    fontFamily: 'Quicksand'
                }}>
                    © {getCurrentYear()} Journées de la MIAGE - Tous droits réservés
                </p>
                <a href="/cgu"
                   target={"_blank"}
                   className={"cgu-link"}>

                    Mentions légales
                </a>
                <Rotate>
                    <div style={{
                        marginTop: 10,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-end",
                    }}>
                        <a href="https://www.facebook.com/people/JNM-2024/61552222584998/">
                            <i className="fab fa-facebook-f"/>
                        </a>
                        <a href="https://www.instagram.com/jnm2024_orleans/">
                            <i className="fab fa-instagram"/>
                        </a>
                        <a href="https://www.linkedin.com/company/jnm-2024/">
                            <i className="fab fa-linkedin"/>
                        </a>
                    </div>
                </Rotate>
            </div>
        </div>
    )
}
