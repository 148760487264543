import { Carousel } from '@trendyol-js/react-carousel';



export const SliderPart = () => {


    const getShow = () => {
        // max width = 768px
        if (window.innerWidth <= 768) {
            return 3;
        } else {
            return 8
        }
    }
    const part = [
        {
            "name": "eid",
            "logo": "https://www.voxygen.fr/hs-fs/hubfs/EI_Logo_carr%C3%A9.png?width=461&height=415&name=EI_Logo_carr%C3%A9.png",
        },
        {
            "name": "sopra steria",
            "logo": "https://www.planetegrandesecoles.com/wp-content/uploads/2022/05/Sans-titre-32.png",
        },
        {
            "name": "Acensi",
            "logo": "https://media.licdn.com/dms/image/C510BAQEvWouB7kp22w/company-logo_200_200/0/1631383612060?e=2147483647&v=beta&t=K6wDURKp8xriUoxz42ph7ewTAulveCfYjOwaRoyci-M",
        },
        {
            "name": "infotel",
            "logo": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRZobCxc4PvvxymFuI_m06mCbHmTRdTHQimuajBsea_MQ&s",
        },
        {
            "name": "Atos",
            "logo": "https://upload.wikimedia.org/wikipedia/fr/thumb/7/79/Atos_logo.svg/1024px-Atos_logo.svg.png?20210719111841"
        },
        {
            "name": "Cgi",
            "logo": "https://ecovelo.com/wp-content/uploads/2017/10/CGI-Logo.jpg",
        },
        {
            "name": "Cat-Amania",
            "logo": "https://media.licdn.com/dms/image/C560BAQHzi9k2lG4vag/company-logo_200_200/0/1630615593491/cat_amania_logo?e=2147483647&v=beta&t=OAhvQI4HVvPzUSE9-gN5xnM-pMC9HYakGM5YR1SUwGo"
        },
        {
            "name": "Extia",
            "logo": "https://res.cloudinary.com/doclgfw1z/image/upload/v1648143279/logo_footer_52102ae462.svg"
        },
        {
            "name": "Agirc-Arrco",
            "logo": "https://www.agirc-arrco.fr/wp-content/uploads/2022/10/LogoAA_signature2022.jpg",
        },
        {
            "name": "Randstad",
            "logo": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRmAahBsShI8EUsBEki7G1JHeYTHKE-AjxHjej4gz34YQ&s",
        },
        {
            "name:": "Apside",
            "logo": "https://annuaire.frenchtechbordeaux.com/rails/active_storage/representations/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaEpJaWsxWmpBNE1EVmtOUzFqTmpJekxUUTBZV1V0WWpSalpTMW1aR1prTWpCaE9ESXdPVGdHT2daRlZBPT0iLCJleHAiOm51bGwsInB1ciI6ImJsb2JfaWQifX0=--2d408e070059e81308bc055362f093c175a2f283/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdCem9MWm05eWJXRjBTU0lJY0c1bkJqb0dSVlE2QzNKbGMybDZaVWtpQ2pFd01qUjRCanNHVkE9PSIsImV4cCI6bnVsbCwicHVyIjoidmFyaWF0aW9uIn19--3c83fddb20b7eaccd2233989affaa7ecacf5f3b8/logo-apside-bleu.png",
        },
        {
            "name:": "Crédit Agricole Titre",
            "logo": "https://ca-titres-recrute.talent-soft.com/Handlers/Image.ashx?imagetype=logo"
        },
        {
            "name:": "Thélem",
            "logo": "https://www.lunaweb.fr/labs/cas-client-thelem-extranet/upload/thelem-logo.png"
        },
        {
            "name:": "MC2i",
            "logo": "https://ebook.mc2i.fr/hubfs/mc2i.svg"
        }
    ]

    setInterval(() => {
        document.querySelector('[data-direction=right]').click();
    }, 3000);

    return (
        <div className={"header-part"}>
            <h1>EN PARTENARIAT AVEC</h1>
            <Carousel
                rightArrow={
                <div
                    data-direction={"right"}
                    style={{
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}>
                    <i style={{
                        color: 'rgb(128, 104, 70)',
                        fontSize: "2rem"
                    }} className={"fas fa-chevron-right"}/>
                </div>
                }
                leftArrow={
                    <div style={{
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}>
                        <i style={{
                            color: 'rgb(128, 104, 70)',
                            fontSize: "2rem"
                        }} className={"fas fa-chevron-left"}/>
                    </div>
                }
               swiping={true}    show={getShow()} slide={1}>


                {part.map((p) => (<>
                        <img
                            className={"partner-logo"}
                            key={p.name}
                            src={p.logo}
                            alt={p.name}
                            style={{
                                backgroundColor: "white",
                                padding: 5,
                                margin: 5,
                                borderRadius: "10px",
                            }}
                        /></>
                ))}
            </Carousel>
        </div>
    )
}

