import {ClassicHeader} from "../components/ClassicHeader";
import {Rotate} from "react-awesome-reveal";
import {HomeHeader} from "../components/HomeHeader";
import {CGUHeader} from "../components/CGUHeader";

export const CGU = () => {

    const getCurrentYear = () => {
        return new Date().getFullYear();
    }

    return (
        <>
            <div className="app-container">
                <CGUHeader/>

                <div className="cgu">
                    <h1>1. Conditions générales d'utilisation</h1>
                    <p>
                        Conformément aux dispositions de la loi n° 2004-575 du 21 juin 2004 pour la confiance en
                        l'économie numérique, il est précisé aux utilisateurs du site JNM 2024 l'identité des
                        différents intervenants dans le cadre de sa réalisation et de son suivi.
                    </p>

                    <h2>2. Édition du site</h2>
                    <p>
                        Le présent site, accessible à l’URL <b>https://www.jnmorleans2024.com/</b> (le « Site »),
                        est édité par :
                        L’association JNM 2024 - Journées Nationales MIAGE 2024, enregistrée auprès de la
                        préfecture/sous-préfecture de 45 - Préfecture Orléans sous le numéro W452019065,
                        ayant son siège situé à 1 rue Emile Zola, 45000 Orléans, représentée par Aurélien
                        BRANCHOUX et GUERIN Marilou dûment habilité(e)
                    </p>

                    <h2>3. Hébergement</h2>
                    <p>
                        Le Site est hébergé par la société OVH SAS, situé 2 rue Kellermann - BP 80157 - 59053
                        Roubaix Cedex 1, (contact téléphonique ou email : 1007)
                    </p>


                    <h2>3. Directeur de publication</h2>
                    <p>
                        Le directeur de publication du Site est Aurélien BRANCHOUX et GUERIN Marilou
                    </p>

                    <h2>Nous contacter</h2>
                    <p>
                        Par téléphone : +33782017161
                        Par email : jnmorleans2024@gmail.com
                        Par courrier : 1 RUE EMILE ZOLA, 45000 Orléans
                    </p>
                </div>

                <div className="footer">
                    <div>
                        <img
                            style={{
                                width: "80px",
                                height: "80px"
                            }}
                            alt="JNM"
                            src="Logo-JNM_20230924%20(1).png"/>
                    </div>
                    <p style={{
                        color: 'white',
                        fontFamily: 'Quicksand'
                    }}>
                        © {getCurrentYear()} Journées de la MIAGE - Tous droits réservés
                    </p>
                    <a href="/cgu"
                       target={"_blank"}
                       className={"cgu-link"}>
                        Mentions légales
                    </a>
                    <Rotate>
                        <div style={{
                            marginTop: 10,
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "flex-end",
                        }}>
                            <a href="https://www.facebook.com/people/JNM-2024/61552222584998/">
                                <i className="fab fa-facebook-f"/>
                            </a>
                            <a href="https://www.instagram.com/jnm2024_orleans/">
                                <i className="fab fa-instagram"/>
                            </a>
                            <a href="https://www.linkedin.com/company/jnm-2024/">
                                <i className="fab fa-linkedin"/>
                            </a>
                        </div>
                    </Rotate>
                </div>
            </div>
        </>
    )
}
